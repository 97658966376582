html, #root, body {
    @apply bg-white h-full font-['Helvetica'];
}

.device-list-container .accordion .close:not(:last-child) {
    border-bottom: 0;
}

.drawer-menu .menu-item:not(:last-child) {
    border-bottom: 0;
}

.menu .active .menu-item {
    @apply bg-white rounded-sm text-blue-950;
}

.menu .menu-item {
    @apply text-white h-10 flex items-center justify-center p-2;
}

.tooltip-triangle {
    width: 0;
    height: 0;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 20px;
}

input[type="range"]::-webkit-slider-runnable-track {
    height: 20px;
    background: #E2E2E2;
    border-radius: 5px;
}

input[type="range"]::-moz-range-track {
    height: 20px;
    background: #E2E2E2;
    border-radius: 20px;
}

input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 18px;
    width: 18px;
    background-color: #E2E2E2;
    border-radius: 50%;
    border: 2px solid #000;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #000;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
    height: 18px;
    width: 18px;
    background-color: #E2E2E2;
    border-radius: 50%;
    border: 1px solid #000;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #000;
}

@media (min-width: 768px) {
    /* This CSS rule will be applied to screens that are 1024 pixels wide or more. */
    .device-list-container {
        max-height: calc(100% - 125px);
        overflow-y: auto;
    }

    .react-confirm-alert-body {
        width: 400px;
    }
}

@media (max-width: 768px) {
    /* This CSS rule will be applied to screens that are 768 pixels wide or less. */
    .device-list-container {
        max-height: calc(100% - 50px);
        overflow-y: auto;
    }

    .react-confirm-alert-body {
        width: 100%;
        padding: 20px;
    }
}

table td, table th {
    @apply py-3 px-1 border border-neutral-200 text-center;
}

.table-listing {
    max-height: calc(100%);
    overflow-y: auto;
}

.toggle {
    --width: 80px;
    --height: 30px;

    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
}

.toggle input {
    display: none;
}

.toggle .slider {
    @apply bg-red-800;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--height));
    height: calc(var(--height));
    border-radius: calc(var(--height) / 2);
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
}

.toggle input:checked + .slider {
    @apply bg-green-800;
}

.toggle input:checked + .slider::before {
    transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
    position: absolute;
    top: 6px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
    @apply text-white;
    content: attr(data-off);
    position: absolute;
    right: 20px;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
    @apply text-white;
    content: attr(data-on);
    position: absolute;
    left: 20px;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.toggle input:checked ~ .labels::after {
    opacity: 0;
}

.toggle input:checked ~ .labels::before {
    opacity: 1;
}

.react-confirm-alert-overlay {
    @apply bg-black bg-opacity-40;
    z-index: 105;
}

/*Date range picker*/
.custom-inline-date-range-container {
    @apply w-[99%]
}

.react-datepicker {
    @apply border border-neutral-200 shadow mx-auto;
}

.custom-inline-date-range-container .react-datepicker, .custom-inline-date-range-container .react-datepicker__month-container {
    @apply w-full;
}

.custom-inline-date-range-container .react-datepicker__week, .custom-inline-date-range-container .react-datepicker__day-names {
    @apply grid grid-cols-7 gap-2;
}

.custom-inline-date-range-container .react-datepicker__day, .custom-inline-date-range-container .react-datepicker__day-name {
    @apply w-full py-2;
}

.react-datepicker__day--selected:hover {
    @apply bg-blue-950;
}

.react-datepicker__day--selected, .react-datepicker__day--in-range {
    @apply bg-blue-900;
}

.react-datepicker__day--range-end {
    @apply bg-blue-900 text-white;
}

.react-datepicker__header {
    @apply bg-blue-50 border-b border-neutral-200;
}

.react-datepicker__navigation {
    @apply p-5;
}

/*loader*/
.loader-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/trackandgo-4a78a.appspot.com/o/favicon.ico?alt=media") no-repeat center;
}

.loader {
    width: 72px;
    height: 72px;
    border: 2px solid #2a2a2a;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    background: #Fa2121;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.loader::before {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Drawer menu */
#drawer-menu.closed {
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
}

#drawer-menu.opened {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
}

/* Popup animation */
.c-popup {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/*Date Picker*/
.react-datepicker-wrapper {
    @apply w-full;
}

.react-datepicker-wrapper .react-datepicker__day--selected {
    @apply bg-blue-950;
}

.react-datepicker-wrapper input {
    @apply w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold;
}


/* Map Options */
.map-options {
    width: 56px;
    max-width: 56px;
}
